<template>
  <div class="isolate-stack">
    <v-card class="mb-4 px-4 pb-4">
      <TimeFilters
        class="mb-3"
        @timeFilterChanged="handleTimeFilterChanged"
      />
      <div class="pl-1 mb-1">
        Location
      </div>
      <v-select
        v-model="selectedLocation"
        label="Choose a location"
        :items="locationDropdown"
        hide-details
        clearable
        solo
      />
    </v-card>

    <v-row>
      <v-col cols="12">
        <v-overlay :value="statsLoading">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>

        <div v-if="stats.ready === false">
          <v-row>
            <v-col cols="12">
              <v-card class="py-4">
                <div class="text-center">
                  No data to display
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row>
            <v-col cols="12">
              <v-card>
                <l-map
                  ref="map"
                  style="height: 35rem; width: 100%;"
                  class="map-styling"
                  :zoom="12"
                  :options="leafletOptions"
                >
                  <l-tile-layer
                    :url="$tileService.url"
                    :attribution="$tileService.attribution"
                  ></l-tile-layer>
                  <l-circle-marker
                    v-for="(cl, idx) in mapCellLocations"
                    :key="'cell-marker-' + idx"
                    :lat-lng="cl"
                    color="#ff0000"
                    fill-color="#ff0000"
                    :interactive="false"
                  ></l-circle-marker>
                </l-map>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  Subscriber counts by tariff/gender/age groups
                </v-card-title>

                <v-simple-table :class="[]">
                  <template #default>
                    <tbody>
                      <tr>
                        <th></th>
                        <th
                          colspan="2"
                          scope="col"
                          class="text-center"
                        >
                          Private
                        </th>
                        <th
                          colspan="2"
                          scope="col"
                          class="text-center"
                        >
                          Business
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th
                          scope="col"
                          class="text-center"
                        >
                          Male
                        </th>
                        <th
                          scope="col"
                          class="text-center"
                        >
                          Female
                        </th>
                        <th
                          scope="col"
                          class="text-center"
                        >
                          Male
                        </th>
                        <th
                          scope="col"
                          class="text-center"
                        >
                          Female
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">
                          18-25
                        </th>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'MALE', 'AG_18_25') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'FEMALE', 'AG_18_25') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'MALE', 'AG_18_25') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'FEMALE', 'AG_18_25') }}</td>
                      </tr>
                      <tr>
                        <th scope="col">
                          25-35
                        </th>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'MALE', 'AG_25_35') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'FEMALE', 'AG_25_35') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'MALE', 'AG_25_35') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'FEMALE', 'AG_25_35') }}</td>
                      </tr>
                      <tr>
                        <th scope="col">
                          35-45
                        </th>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'MALE', 'AG_35_45') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'FEMALE', 'AG_35_45') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'MALE', 'AG_35_45') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'FEMALE', 'AG_35_45') }}</td>
                      </tr>
                      <tr>
                        <th scope="col">
                          45-55
                        </th>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'MALE', 'AG_45_55') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'FEMALE', 'AG_45_55') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'MALE', 'AG_45_55') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'FEMALE', 'AG_45_55') }}</td>
                      </tr>
                      <tr>
                        <th scope="col">
                          55-65
                        </th>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'MALE', 'AG_55_65') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'FEMALE', 'AG_55_65') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'MALE', 'AG_55_65') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'FEMALE', 'AG_55_65') }}</td>
                      </tr>
                      <tr>
                        <th scope="col">
                          65+
                        </th>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'MALE', 'AG_65_AND_UP') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('PRIVATE', 'FEMALE', 'AG_65_AND_UP') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'MALE', 'AG_65_AND_UP') }}</td>
                        <td>{{ stats.cohorts.getFromMatrix('BUSINESS', 'FEMALE', 'AG_65_AND_UP') }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  Subscriber nationality breakdown
                </v-card-title>

                <v-simple-table>
                  <tbody>
                    <tr>
                      <th scope="col">
                        Domestic
                      </th>
                      <td>{{ stats.nationalities.domestic.toFixed() }}%</td>
                    </tr>

                    <tr>
                      <th scope="col">
                        Foreign
                      </th>
                      <td>{{ stats.nationalities.foreign.toFixed() }}%</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  Top 10 countries by subscriber counts
                </v-card-title>

                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Country name</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(country) in stats.countries"
                      :key="country.countryIso"
                    >
                      <td v-if="country.count != 0">{{ country.countryName || 'Unknown' }}</td>
                      <td v-if="country.count != 0">{{ country.count }}</td>
                    </tr>
                    <tr v-if="stats.countries.length === 0">
                      <td
                        colspan="2"
                        class="text-center"
                      >
                        No data available
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  isNil as _isNil,
  get as _get,
  has as _has,
} from 'lodash'
import gql from 'graphql-tag'
import {
  LMap,
  LTileLayer,
  LCircleMarker,
} from 'vue2-leaflet'

import TimeFilters from '../components/heatmaps-shared/TimeFilters.vue'

export default {
  components: {
    LMap,
    LCircleMarker,
    LTileLayer,
    TimeFilters,
  },

  data() {
    return {
      leafletOptions: {
        minZoom: 13,
        maxZoom: 16,
        /* eslint-disable-next-line no-undef */
        maxBounds: L.latLngBounds(L.latLng(45.451196, 14.287033), L.latLng(45.267372, 14.581686)),
      },

      selectedFrom: null,
      selectedUntil: null,
      selectedTimeRange: null,

      selectedLocation: null,
      selectedLocationCells: [],
    }
  },

  computed: {
    locationDropdown() {
      return !_isNil(this.picolusAvailableLocations) ? this.picolusAvailableLocations.map(l => l.locationName) : []
    },

    stats() {
      const result = {
        ready: false,
        total: 0,
        cohorts: {
          total: 0,
          matrix: {},

          getFromMatrix(t, g, a) {
            return _get(this.matrix, [t, g, a], 0)
          },
        },
        countries: [],
        nationalities: {
          domestic: 0,
          foreign: 0,
        },
      }

      let readyState = 0

      if (!_isNil(this.heatmapByCountryData)) {
        if (this.heatmapByCountryData.total > result.total) {
          result.total = this.heatmapByCountryData.total
        }

        this.heatmapByCountryData.cells.forEach(cell => {
          cell.countries.forEach(country => {
            const existing = result.countries.find(c => c.countryIso === country.countryIso)

            if (existing === undefined) {
              const entry = {
                countryIso: country.countryIso,
                countryName: country.countryName,
                count: country.count || 0,
              }

              result.countries.push(entry)
            } else {
              existing.count += country.count
            }

            // Aggregate nationalities
            if (country.countryIso.toLowerCase() === 'hr') {
              result.nationalities.domestic += country.count
            } else {
              result.nationalities.foreign += country.count
            }
          })
        })

        // Turn nationality aggregates into percentages
        const nationalityMultiplier = 100 / Math.max(this.heatmapByCountryData.total, 1)
        result.nationalities.domestic *= nationalityMultiplier
        result.nationalities.foreign *= nationalityMultiplier

        // Sort countries by subscriber counts, descending, slice 10
        result.countries = result.countries.sort((lhs, rhs) => rhs.count - lhs.count).slice(0, 10)

        // Reduce to top 10 countries instead of displaying all of them
        // result.countries.splice(0, 10)

        readyState += 1
      }

      if (!_isNil(this.heatmapByCohortData)) {
        if (this.heatmapByCohortData.total > result.total) {
          result.total = this.heatmapByCohortData.total
        }

        this.heatmapByCohortData.cells.forEach(cellData => {
          cellData.cohorts.forEach(cohort => {
            if (!_has(result.cohorts.matrix, cohort.tariff)) {
              result.cohorts.matrix[cohort.tariff] = {}
            }

            if (!_has(result.cohorts.matrix[cohort.tariff], cohort.gender)) {
              result.cohorts.matrix[cohort.tariff][cohort.gender] = {}
            }

            if (!_has(result.cohorts.matrix[cohort.tariff][cohort.gender], cohort.ageGroup)) {
              result.cohorts.matrix[cohort.tariff][cohort.gender][cohort.ageGroup] = 0
            }

            result.cohorts.matrix[cohort.tariff][cohort.gender][cohort.ageGroup] += cohort.count
          })
        })

        readyState += 1
      }

      if (readyState === 2) {
        result.ready = true
      }

      return result
    },

    statsLoading() {
      return this.$apollo.queries.heatmapByCountryData.loading || this.$apollo.queries.heatmapByCohortData.loading
    },

    mapCellLocations() {
      const result = []

      if (
        _isNil(this.picolusAvailableLocations)
        || this.picolusAvailableLocations.length === 0
        || _isNil(this.selectedLocation)
      ) {
        return result
      }

      const loc = this.picolusAvailableLocations.find(l => l.locationName === this.selectedLocation)
      if (_isNil(loc)) return result

      loc.cellLocations.forEach(cl => {
        /* eslint-disable-next-line no-undef */
        result.push(L.latLng(cl.lat, cl.lng))
      })

      return result
    },
  },

  watch: {
    selectedLocation(newVal) {
      if (!_isNil(newVal)) {
        const result = this.picolusAvailableLocations.find(l => l.locationName === newVal)

        if (!_isNil(result)) {
          this.selectedLocationCells.splice(0)
          this.selectedLocationCells.push(...result.cellIds)
        }
      }
    },

    mapCellLocations(newVal) {
      if (newVal.length === 0) return

      /* eslint-disable-next-line no-undef */
      const bbox = L.latLngBounds(Array.from(newVal))

      this.$nextTick().then(() => {
        this.$refs.map?.mapObject.flyToBounds(bbox)
      })
    },
  },

  apollo: {
    picolusAvailableLocations: {
      query: gql`
        query {
          picolusAvailableLocations {
            locationName
            cellIds
            cellLocations {
              lat
              lng
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
    },

    heatmapByCountryData: {
      query: gql`
        query ($cellIds: [String!], $from: DateTimeTz!, $until: DateTimeTz!, $timeRange: AnalyticsTimeRange!) {
          heatmapByCountryData(timeRange: $timeRange, from: $from, until: $until, cellIds: $cellIds) {
            total
            cells {
              countries {
                countryIso
                countryName
                count
              }
            }
          }
        },
      `,
      variables() {
        return {
          cellIds: this.selectedLocationCells,
          from: this.selectedFrom,
          until: this.selectedUntil,
          timeRange: this.selectedTimeRange,
        }
      },
      fetchPolicy: 'network-only',
      skip() {
        return this.selectedLocationCells.length === 0
      },
    },

    heatmapByCohortData: {
      query: gql`
        query ($cellIds: [String!], $from: DateTimeTz!, $until: DateTimeTz!, $timeRange: AnalyticsTimeRange!) {
          heatmapByCohortData(timeRange: $timeRange, from: $from, until: $until, cellIds: $cellIds) {
            total
            cells {
              cohorts {
                ageGroup
                gender
                tariff
                count
              }
            }
          }
        }
      `,
      variables() {
        return {
          cellIds: this.selectedLocationCells,
          from: this.selectedFrom,
          until: this.selectedUntil,
          timeRange: this.selectedTimeRange,
        }
      },
      fetchPolicy: 'network-only',
      skip() {
        return this.selectedLocationCells.length === 0
      },
    },
  },

  methods: {
    handleTimeFilterChanged(from, until, range) {
      this.selectedFrom = this.$moment(from).format()
      this.selectedUntil = this.$moment(until).format()
      this.selectedTimeRange = range
    },
  },
}
</script>
